import "./styles.scss";
import NispandLogo from "../../assets/Images/NispandLogo.png";
import AppStore from "../../assets/Icons/AppStore.svg";
import PlayStore from "../../assets/Icons/PlayStore.svg";
import TwitterIcon from "../../assets/Icons/TwitterIcon.svg";
import LinkedInIcon from "../../assets/Icons/LinkedInIcon.svg";
import FacebookIcon from "../../assets/Icons/FacebookIcon.svg";
import youtubeIcon from "../../assets/Icons/youtubeIcon.svg";
import instaIcon from "../../assets/Icons/instaIcon.svg";
import DribbleIcon from "../../assets/Icons/DribbleIcon.svg";
import { useNavigate } from "react-router-dom";

const FooterSection = () => {
  const navigate = useNavigate();
  const currentYear = new Date().getFullYear();

  const handleNavigation = (num) => {
    switch (num) {
      case 1:
        navigate("/about");
        // window.scrollTo(0, 0);
        break;
      case 2:
        navigate("/business");
        // window.scrollTo(0, 0);
        break;
      case 3:
        navigate("/privacy-policy");
        // window.scrollTo(0, 0);
        break;
      case 4:
        navigate("/terms-condition");
        // window.scrollTo(0, 0);
        break;
        case 6:
        navigate("/blog");
        break;
      case 5:
        navigate("/contact");
        // window.scrollTo(0, 0);
        break;
      case 7:
        navigate("/");
        // window.scrollTo(0, 0);
        break;
      default:
    }
  };

  // const handleScrollToTop = () => {
  //   window.scrollTo({ top: 0, behavior: "smooth", duration: 1000 });
  // };

  return (
    <div>
      <div className="footer-main-sec">
        <div className="left-sec">
          <img src={NispandLogo} alt="Nispand Logo" onClick={()=>handleNavigation(7)}/>
          <div className="text">
          Join Nispand and begin the journey of your dreams! We empower you to make your health goals a reality. Peacefully. At your own pace.
          </div>
        </div>
        <div className="right-sec">
          <div className="headings">
            <div className="head">COMPANY</div>
            <div className="sub-head" onClick={()=>handleNavigation(7)}>
              Home
            </div>
            <div className="sub-head" onClick={() => handleNavigation(6)}>
              Blogs
            </div>
            <div className="sub-head" onClick={() => handleNavigation(1)}>
              About Us
            </div>
            <div className="sub-head" onClick={() => handleNavigation(2)}>
              Nispand Business
            </div>
            <div className="sub-head" onClick={() => handleNavigation(5)}>
            Contact
            </div>
          </div>
          <div className="headings">
            <div className="head">POLICIES</div>
            <div className="sub-head" onClick={() => handleNavigation(4)}>
              Terms and Conditions
            </div>
            <div className="sub-head" onClick={() => handleNavigation(3)}>
              Privacy Policy
            </div>
            {/* <div className="sub-head">Terms of Use</div> */}
          </div>
          <div className="buttons">
            <img src={AppStore} alt="App Store Logo" onClick={()=>window.open("https://apps.apple.com/in/app/nispand-meditation-and-sleep/id1609608907")}/>
            <img src={PlayStore} alt="Play Store Logo" onClick={()=>window.open("https://play.google.com/store/apps/details?id=com.nispand.com&hl=en_IN&gl=US")}/>
          </div>
        </div>
      </div>
      <div className="link-sec">
        <div className="text">© {currentYear} Nispand All rights reserved.</div>
        <div className="social-icons">
          <img src={TwitterIcon} alt="Twitter Icon" onClick={()=>window.open("https://x.com/NispandApp", "_blank")} />
          <img src={LinkedInIcon} alt="LinkedIn Icon" onClick={()=>window.open("https://www.linkedin.com/company/nispand/", "_blank")}/>
          <img src={FacebookIcon} alt="Facebook Icon" onClick={()=>window.open("https://www.facebook.com/NispandWellnessSuperApp", "_blank")}/>
          <img src={youtubeIcon} alt="yt Icon" onClick={()=>window.open("https://www.youtube.com/@NispandWellnessSuperApp", "_blank")}/>
          <img src={instaIcon} alt="Hands Icon" onClick={()=>window.open("https://www.instagram.com/nispand.app/", "_blank")}/>
          {/* <img src={DribbleIcon} alt="Dribble Icon" /> */}
        </div>
      </div>
    </div>
  );
};
export default FooterSection;
